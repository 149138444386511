import { Space, Spin } from "antd";
import React from "react";
import { LoadingOutlined } from "@ant-design/icons";
import Text from "antd/lib/typography/Text";

const OpeningLoading = () => {
  return (
    <Space>
      <Spin
        size="small"
        style={{ color: "black" }}
        indicator={<LoadingOutlined spin />}
      />
      <Text>
        <small>Loading..</small>
      </Text>
    </Space>
  );
};

export default OpeningLoading;
