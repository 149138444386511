import { notFound } from "next/navigation";
import {
  AppointmentHistoryData,
  AppointmentReviewData,
  ICreateAppointmentArgs,
  LocationOpeningsData,
  IRescheduleAppointmentArgs,
  UserData,
  ICancelAppointmentArgs,
  UpcomingAppointmentResponse,
  IServiceRequestArgs,
  IMessageArgs,
  ICreatePaymentMethodArgs,
  UserOpeningsData,
  LocationData,
  BlogData,
  UserExperienceResponse,
  AppointmentFeedbackResponse,
  Location,
  PhotosReponse,
  RequestQuoteResponse,
  UsersData,
} from "./types";

export class EditionJson {
  static ENDPOINT =
    process.env.NODE_ENV === "development"
      ? "http://localhost:3000/dev"
      : `https://api.eddiesedition.com/pro`;

  static fetchRest = async <T>(url: string, data = {}): Promise<T> => {
    const res = await fetch(url, {
      cache: "no-store",
      headers: {
        Authorization: `Bearer ${process.env.NEXT_PUBLIC_EDITION_TOKEN}`,
      },
      ...data,
    });

    if (!res.ok) {
      if (res.status === 404) {
        notFound(); // Trigger Next.js Not Found page
      }
      const errorData = await res.text();
      throw new Error(errorData);
    }

    const json = await res.json();
    return json.data as T; // Cast the returned JSON data to the generic type T
  };

  static getLocation = async (pathId: string): Promise<LocationData> =>
    EditionJson.fetchRest(`${EditionJson.ENDPOINT}/locations/${pathId}`);

  static getLocations = async (): Promise<Location[]> =>
    EditionJson.fetchRest(`${EditionJson.ENDPOINT}/locations`);

  static getPhotos = async (): Promise<PhotosReponse[]> =>
    EditionJson.fetchRest(`${EditionJson.ENDPOINT}/photos`);

  static getPhoto = async (photoId: string): Promise<any> =>
    EditionJson.fetchRest(`${EditionJson.ENDPOINT}/photos/${photoId}`);

  static getLocationOpeningsByServices = async (
    locationId: string,
    date: string,
    servicesCategories: {
      category: number;
      optionId?: string;
    }[]
  ): Promise<LocationOpeningsData[]> => {
    const queryArgs: { [key: string]: any } = {
      locationId,
      date,
      services: JSON.stringify(servicesCategories),
    };
    const query = new URLSearchParams(queryArgs).toString();
    return EditionJson.fetchRest(
      `${EditionJson.ENDPOINT}/locations/detail/openings?${query}`
    );
  };

  static getAppointment = async (
    appointmentId: string
  ): Promise<AppointmentReviewData> =>
    EditionJson.fetchRest(
      `${EditionJson.ENDPOINT}/appointments/review/${appointmentId}`
    );

  static getUser = async (pathId: string): Promise<UserData> =>
    await EditionJson.fetchRest<UserData>(
      `${EditionJson.ENDPOINT}/users/${pathId}`
    );

  static getUsers = async () =>
    await EditionJson.fetchRest<UsersData>(`${EditionJson.ENDPOINT}/users`);

  static getOpeningsByDuration = async (
    pathId: string,
    date: string,
    duration: number
  ): Promise<UserOpeningsData[]> => {
    const query = new URLSearchParams({
      pathId,
      date,
      duration,
    } as any).toString();
    return EditionJson.fetchRest<UserOpeningsData[]>(
      `${EditionJson.ENDPOINT}/users/openings?${query}`
    );
  };

  static getStylistsByServiceCode = async (
    locationId: string,
    serviceCategories: number[]
  ) => {
    const queryArgs: { [key: string]: any } = {
      locationId,
      serviceCategories: JSON.stringify(serviceCategories),
    };

    const query = new URLSearchParams(queryArgs).toString();
    return EditionJson.fetchRest(
      `${EditionJson.ENDPOINT}/locations/users?${query}`
    );
  };

  static getUserNextOpeningsByDuration = async (
    pathId: string,
    date: string,
    duration: number
  ): Promise<(UserOpeningsData & { date: string })[]> => {
    const query = new URLSearchParams({
      pathId,
      date,
      duration,
    } as any).toString();
    return EditionJson.fetchRest<(UserOpeningsData & { date: string })[]>(
      `${EditionJson.ENDPOINT}/users/next-openings?${query}`
    );
  };

  static createAppointment = async (
    args: ICreateAppointmentArgs
  ): Promise<{ appointmentId: string }> => {
    // Copy the args object
    const queryArgs: { [key: string]: any } = { ...args };

    if (queryArgs.services) {
      queryArgs.services = JSON.stringify(queryArgs.services);
    }

    const query = new URLSearchParams(queryArgs).toString();

    return EditionJson.fetchRest(
      `${EditionJson.ENDPOINT}/appointments/create?${query}`,
      {
        method: "POST",
      }
    );
  };

  static rescheduleAppointment = async (
    args: IRescheduleAppointmentArgs
  ): Promise<{ appointmentId: string }> => {
    // Copy the args object
    const queryArgs: { [key: string]: any } = { ...args };

    if (queryArgs.services) {
      queryArgs.services = JSON.stringify(queryArgs.services);
    }

    const query = new URLSearchParams(queryArgs).toString();
    return EditionJson.fetchRest(
      `${EditionJson.ENDPOINT}/appointments/reschedule?${query}`,
      {
        method: "POST",
      }
    );
  };

  static cancelAppointmentWithCode = async ({
    appointmentId,
    code,
  }: ICancelAppointmentArgs) => {
    return EditionJson.fetchRest(
      `${EditionJson.ENDPOINT}/appointments/cancel/${appointmentId}/${code}`,
      {
        method: "POST",
      }
    );
  };

  static getAppointmentHistory = async (
    phoneNumber: string,
    userId: string
  ): Promise<AppointmentHistoryData> => {
    phoneNumber = removeChars(phoneNumber);
    const data: AppointmentHistoryData = await EditionJson.fetchRest(
      `${EditionJson.ENDPOINT}/appointments/lookup/${phoneNumber}?userId=${userId}`
    );

    // Filter out past appointments
    data.upcomingAppointments = data.upcomingAppointments.filter(
      (appointment) => {
        return new Date(appointment.datetime) > new Date();
      }
    );

    return data;
  };

  static getUpcomingAppointments = async (
    phoneNumber: string
  ): Promise<UpcomingAppointmentResponse[]> => {
    return EditionJson.fetchRest(
      `${EditionJson.ENDPOINT}/appointments/upcoming/${phoneNumber}`
    );
  };

  static sendOPTMessage = async (phoneNumber: string) => {
    phoneNumber = removeChars(phoneNumber);
    return EditionJson.fetchRest(
      `${EditionJson.ENDPOINT}/sms/send/opt-in/${phoneNumber}`,
      {
        method: "POST",
      }
    );
  };

  static sendVerificationCode = async (phoneNumber: string) => {
    phoneNumber = removeChars(phoneNumber);
    return EditionJson.fetchRest(
      `${EditionJson.ENDPOINT}/sms/send/verification/${phoneNumber}`,
      {
        method: "POST",
      }
    );
  };

  static sendVerificationCodeForCancel = async (appointmentId: string) => {
    return EditionJson.fetchRest(
      `${EditionJson.ENDPOINT}/sms/send/appointment/${appointmentId}`,
      {
        method: "POST",
      }
    );
  };

  static verifyVerificationCode = async (
    phoneNumber: string,
    code: string
  ): Promise<boolean> => {
    const query = new URLSearchParams({
      phoneNumber,
      code,
    } as any).toString();
    return EditionJson.fetchRest(
      `${EditionJson.ENDPOINT}/verify-code?${query}`,
      {
        method: "POST",
      }
    );
  };

  static sendServiceRequest = async (args: IServiceRequestArgs) => {
    const query = new URLSearchParams(args as any).toString();
    return EditionJson.fetchRest(
      `${EditionJson.ENDPOINT}/requests/send?${query}`,
      {
        method: "POST",
      }
    );
  };

  static sendTextToStylist = async (args: IMessageArgs) => {
    const query = new URLSearchParams(args as any).toString();
    return EditionJson.fetchRest(
      `${EditionJson.ENDPOINT}/chats/text?${query}`,
      {
        method: "POST",
      }
    );
  };

  static sendMessageForAppointment = async (
    appointmentId: string,
    message: string
  ) => {
    const query = new URLSearchParams({ message } as any).toString();
    return EditionJson.fetchRest(
      `${EditionJson.ENDPOINT}/appointments/contact/${appointmentId}?${query}`,
      {
        method: "POST",
      }
    );
  };

  static createPaymentMethod = async (args: ICreatePaymentMethodArgs) => {
    const query = new URLSearchParams({ ...args }).toString();

    return EditionJson.fetchRest(
      `${EditionJson.ENDPOINT}/payments/create-payment-method?${query}`,
      {
        method: "POST",
      }
    );
  };
  /*
    get customer's payment method.
  */
  static getPaymentMethod = async (
    phoneNumber: string
  ): Promise<{ paymentMethod: any }> => {
    return EditionJson.fetchRest(
      `${EditionJson.ENDPOINT}/payments/get?phoneNumber=${phoneNumber}`
    );
  };

  static addAppointmentNote = async (appointmentId: string, note: string) => {
    const params: Record<string, string> = {};
    params.appointmentId = appointmentId;
    params.note = note;

    return EditionJson.fetchRest(
      `${EditionJson.ENDPOINT}/appointments/note?${new URLSearchParams(
        params
      )}`,
      {
        method: "POST",
      }
    );
  };

  static requestSpecialOptions = async (
    appointmentId: string,
    args: { hasExtraLength: boolean; hasNote: boolean; note: string }
  ) => {
    const params: Record<string, string> = {};
    params.appointmentId = appointmentId;
    params.hasExtraLength = args.hasExtraLength.toString();
    params.hasNote = args.hasNote.toString();
    params.note = args.note;

    return EditionJson.fetchRest(
      `${EditionJson.ENDPOINT}/appointments/request?${new URLSearchParams(
        params
      )}`,
      {
        method: "POST",
      }
    );
  };

  static createFeedback = async (values: {
    appointmentId: string;
    time: string;
    service: string;
    quality: string;
    note: string;
  }) => {
    const query = new URLSearchParams(values);
    return EditionJson.fetchRest(
      `${EditionJson.ENDPOINT}/appointments/feedback/create?${query}`,
      {
        method: "POST",
      }
    );
  };

  static getFeedback = async (
    appointmentId: string
  ): Promise<AppointmentFeedbackResponse> =>
    EditionJson.fetchRest(
      `${EditionJson.ENDPOINT}/appointments/feedback/${appointmentId}`
    );

  static getStylistExperience = async (
    userId: string
  ): Promise<UserExperienceResponse> =>
    EditionJson.fetchRest(`${EditionJson.ENDPOINT}/users/photos/${userId}`);

  static getBlogArticles = async (): Promise<BlogData[]> =>
    EditionJson.fetchRest(`${EditionJson.ENDPOINT}/blog`);

  static getBlogArticle = async (articlePath: string): Promise<BlogData> =>
    EditionJson.fetchRest(`${EditionJson.ENDPOINT}/blog/${articlePath}`);

  static getQuote = async (requestId: string): Promise<RequestQuoteResponse> =>
    EditionJson.fetchRest(`${EditionJson.ENDPOINT}/request/${requestId}`);
}

export const removeChars = (str: string) => {
  return str.replace(/[^0-9]/g, "");
};
