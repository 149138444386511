import { DB_DATE_FORMAT, DEFAULT_DATETIME_FORMAT, WENT_WRONG } from "@/consts";
import dayjs from "dayjs";
import dayjsFormat from "dayjs/plugin/customParseFormat";
import duration from "dayjs/plugin/duration";
import utc from "dayjs/plugin/utc";
import timezone from "dayjs/plugin/timezone";
dayjs.extend(dayjsFormat);
dayjs.extend(duration);
dayjs.extend(utc);
dayjs.extend(timezone);

export const sortTimesInObj = (arrayOfTimes: any[]) => {
  return arrayOfTimes.sort((a, b) => {
    const aParts = getNumericParts(a.time);
    const bParts = getNumericParts(b.time);
    // Sorts by hour then minute
    return aParts[0] - bParts[0] || aParts[1] - bParts[1];
  });
  function getNumericParts(time: string) {
    // accounts formats of 9:15 AM and 09:15:30 but does not handle AM/PM in comparison
    return time
      .split(" ")[0]
      .split(":")
      .map((x) => +x);
  }
};

// Return (xxx) xxx-xxxx format
export function formatPhoneNumber(phoneNumberString: string) {
  var cleaned = ("" + phoneNumberString).replace(/\D/g, "");
  var match = cleaned.match(/^(1|)?(\d{3})(\d{3})(\d{4})$/);
  if (match) {
    return ["(", match[2], ") ", match[3], "-", match[4]].join("");
  }
  return null;
}

export const formatDatetime = (date: string, time: string) => {
  return dayjs(`${date} ${time}`).format(DEFAULT_DATETIME_FORMAT);
};

//C onverts a date in the format YYYY-MM-DD
export const formatDate = (date: Date) => {
  return dayjs(date).format(DB_DATE_FORMAT);
};

// Converts a time string in the format a militery time to a 12-hour time
export const formatTime = (time: string) => {
  return dayjs(time, "H:mm", true).format("h:mm a");
};

export const extractErrorMessage = (error: any) => {
  if (error && typeof error.message === "string") {
    try {
      const errorMessageJSON = JSON.parse(error.message);
      if (errorMessageJSON && errorMessageJSON.message) {
        return errorMessageJSON.message;
      }
    } catch (parsingError) {
      console.error("Error parsing the error message:", parsingError);
      return WENT_WRONG;
    }
  }

  // Default error message
  return WENT_WRONG;
};

// Returns an array of dates between the start and end dates
export const getDatesBetween = (startDate: string, endDate: string) => {
  const dates = [];
  let currentDate = dayjs(startDate, DB_DATE_FORMAT);
  const end = dayjs(endDate, DB_DATE_FORMAT);

  while (currentDate.isBefore(end) || currentDate.isSame(end)) {
    dates.push(currentDate.toDate());
    currentDate = currentDate.add(1, "day");
  }

  return dates;
};

export const formatMinutes = (v: number) => {
  if (!v) return null;

  const duration = dayjs.duration(v, "minutes");
  const hours = duration.hours();
  const minutes = duration.minutes();

  if (hours && minutes) {
    return `${hours} hr${hours > 1 ? "s" : ""} ${minutes} min${
      minutes > 1 ? "s" : ""
    }`;
  } else if (hours) {
    return `${hours} hr${hours > 1 ? "s" : ""}`;
  } else if (minutes) {
    return `${minutes} min${minutes > 1 ? "s" : ""}`;
  } else {
    return "0 mins"; // Optional: You can return an empty string if needed.
  }
};

export const getPhotoHeight = (gridType: "SINGLE" | "MULTIPLE") => {
  return gridType === "SINGLE" ? 280 : 140;
};

export const convertToTimezone = (date: Date, timezone: string): string => {
  return dayjs(date).tz(timezone).format(DEFAULT_DATETIME_FORMAT);
};
