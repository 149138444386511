import React from "react";
import { Divider, Typography } from "antd";
import { AppointmentForm } from "@/lib/hooks/useAppointment";
import { formatDatetime, formatMinutes } from "@/lib/helpers";

const { Paragraph, Text } = Typography;

const AppointmentReview = ({
  appointment,
}: {
  appointment: AppointmentForm;
}) => {
  if (!appointment) return null;
  const datetime = formatDatetime(appointment.date, appointment.time);
  let duration = 0;
  return (
    <>
      <Typography>
        <Paragraph>
          <Text strong>Stylist:</Text> <Text>{appointment.stylistName}</Text>
        </Paragraph>
        <Paragraph>
          <Text strong>Service:</Text>

          {appointment.services.map((service, i) => {
            duration += service.option
              ? service.option.duration
              : service.duration;
            return (
              <div key={`service-${i}`}>
                <Text>{service.title}</Text>
                {service.option && (
                  <Text>{` (Option: ${service.option.title})`}</Text>
                )}
                <Divider type="vertical" />

                <Text>
                  $
                  {service.option
                    ? `${service.option.price}`
                    : `${service.price} ${service.up ? "+" : ""}`}
                </Text>

                {/* {service.discount && (
                TODO: Fix this
                  <>
                    {"  "}
                    <span className="has-text-danger">
                      ${service.price - service.discount.discountAmount}
                    </span>
                  </>
                )} */}
                {service.description && (
                  <>
                    <br />
                    <Text type="secondary" italic>
                      <small>{service.description}</small>
                    </Text>
                  </>
                )}
              </div>
            );
          })}
        </Paragraph>
        <Paragraph>
          <Text strong>Duration:</Text> <Text>{formatMinutes(duration)}</Text>
        </Paragraph>
        <Paragraph>
          <Text strong>At:</Text> <Text>{datetime}</Text>
        </Paragraph>
        {appointment.locationName && (
          <Paragraph>
            <Text strong>Location: </Text>{" "}
            <Text>{appointment.locationName}</Text>
          </Paragraph>
        )}
      </Typography>
    </>
  );
};

export default AppointmentReview;
